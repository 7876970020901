import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { AbstractControl, FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { debounceTime, take } from 'rxjs/operators';
import { Service, ServiceStatus, ServiceType } from '../../../../shared/models/entities/service';
import { PagedRequest } from '../../../../shared/models/api/shared/paged/paged-request';
import { ServicesFiltersRequest } from '../../../../shared/models/api/services/services-filters-requests.interface';
import { Customer } from 'src/app/shared/models/entities/customer';
import { CustomerSite } from 'src/app/shared/models/entities/customer-site';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Structure } from 'src/app/shared/models/entities/structure';
import { Preparer } from 'src/app/shared/models/entities/preparer';
import {
  addDays,
  addMonths,
  endOfMonth,
  endOfToday,
  endOfWeek,
  isBefore,
  isEqual,
  startOfMonth,
  startOfToday,
  startOfWeek
} from 'date-fns';
import { NzMessageRef, NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { ServicesService } from '../../../../shared/services/api/services.service';
import { UserService } from 'src/app/shared/services/api/user.service';
import { UserRoleType } from 'src/app/shared/models/entities/user-role';
import { ApolloError } from '@apollo/client/core';
import { ServiceActions } from 'src/app/shared/models/entities/service-actions';
import { ActorsService } from '../../../../shared/services/api/actors.service';
import { ApiErrorMessageUtil } from '../../../../shared/utils/api-error-message.util';
import { Utils } from 'src/app/shared/utils/utils';

@Component({
  selector: 'laveo-filter-services',
  templateUrl: './filter-services.component.html',
  styleUrls: ['./filter-services.component.scss']
})
export class FilterServicesComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() checked: Service[] = [];
  @Input() queryStringFilters = new BehaviorSubject<PagedRequest<ServicesFiltersRequest> | null>(null);
  @Input() autofocus = false;
  @Output() filtersChanged = new EventEmitter<PagedRequest<ServicesFiltersRequest>>();
  @Output() checkedChanged = new EventEmitter();

  @ViewChild('copyForm') private copyFormContent: TemplateRef<any>;
  @ViewChild('workflowForm') private workflowFormContent: TemplateRef<any>;
  @ViewChild('exportForm') private exportFormContent: TemplateRef<any>;
  @ViewChild('search') private searchInput: ElementRef<HTMLInputElement>;

  actionLoading = false;
  filtersAreVisible = false;
  filters: UntypedFormGroup;
  copyForm: UntypedFormGroup;
  exportForm: UntypedFormGroup;
  filterStatus: ServiceStatus[] = Object.values(ServiceStatus).filter(s => s !== ServiceStatus.created);
  filterTypes: ServiceType[] = Object.values(ServiceType);
  filterClientTypeEntity = [Customer, CustomerSite];
  filterStructureTypeEntity = [Preparer, Structure];
  userCanSeeInvoiceFilter = false;
  userCanSeeStructureFilter = true;
  userCanSeeCustomerFilter = true;
  filterStructurePlaceholder = 'Structures et préparateurs';
  filterCientPlaceholder = 'Clients et sites client';
  dateRanges: Record<string, Date[]> = {};
  sortType: 'ASC' | 'DESC' = 'ASC';
  defaultTime = new Date(0, 0, 0, 0, 0, 0);
  cancelComment = new FormControl('', [Validators.required]);
  isAdmin = false;

  private isStructure = false;
  private subscriptions: Subscription[] = [];
  private currentLoadingMessageReference?: NzMessageRef;
  private confirmModal?: NzModalRef;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly servicesService: ServicesService,
    private readonly actorsService: ActorsService,
    private readonly userService: UserService,
    private readonly modal: NzModalService,
    private readonly message: NzMessageService
  ) {}

  get checkedContainsDeletable(): boolean {
    const cancelable = this.checked.find(service => service.actions.includes(ServiceActions.cancel));
    return cancelable !== undefined;
  }

  get checkedPossibleActions(): ServiceActions[] {
    return [... new Set(this.checked.flatMap(service => service.actions).filter(action => ![
      ServiceActions.cancel,
      ServiceActions.update,
      ServiceActions.upload_vehicle_state
    ].includes(action)).filter(action => action !== ServiceActions.perform || (action === ServiceActions.perform && !this.isStructure)))]; // TODO rajouter un rôle car c'est franchement pas top de faire ça comme ça pour perform
  }

  get deletable(): Service[] {
    return this.checked.filter(service => [
      ServiceStatus.asked,
      ServiceStatus.planned,
      ServiceStatus.proposed
    ].includes(service.status));
  }

  ngOnInit(): void {
    this.setFiltersForm();
    this.loadRole();

    this.subscriptions.push(
      this.queryStringFilters.subscribe(f => this.setFilterFromQueryString(f))
    );
  }

  ngAfterViewInit(): void {
    if (this.autofocus) {
      setTimeout(() => {
        this.searchInput.nativeElement.focus();
      }, 100);
    }
  }

  ngOnDestroy(): void {
    for (const s of this.subscriptions) {
      s.unsubscribe();
    }
  }

  toggleFilter(): void {
    this.filtersAreVisible = !this.filtersAreVisible;
  }

  toggleSort(): void {
    this.sortType = this.sortType === 'ASC' ? 'DESC' : 'ASC';
    this.filters.get('sort')?.setValue(this.sortType);
  }

  copyChecked(): void {
    const modalReference = this.modal.create({
      nzTitle: 'Renouvellement de prestation',
      nzContent: this.copyFormContent,
      nzOkText: 'Renouveler',
      nzCancelText: 'Retour',
      nzData: {
        copyForm: this.copyForm,
        disabledDates: (currentDate: Date): boolean => isBefore(currentDate, startOfToday()),
        isDateTooSoon: (currentDate: Date): boolean => isBefore(currentDate, addDays(new Date(), 2)),
        dateValidation: (control: AbstractControl): string | AbstractControl | null => isBefore(control.value, addDays(new Date(), 2)) ? 'warning' : control,
        disabledHours: (): number[] => [0, 1, 2, 3, 4, 5, 22, 23]
      },
      nzOkDisabled: true,
      nzOnOk: () => {
        this.actionLoading = true;

        const date: Date = this.copyForm.value.date;
        if (this.copyForm.value.time) {
          date.setHours(this.copyForm.value.time.getHours(), this.copyForm.value.time.getMinutes(), 0, 0);
        } else {
          date.setHours(0, 0, 0, 0);
        }

        this.servicesService.copyMultiple(this.checked.map(s => s.id), date).subscribe({
          next: response => {
            if ((response.data?.length ?? 0) === 0) {
              this.message.error('Une erreur est survenue lors du renouvellement. Réessayer plus tard.');
            } else if ((response.data?.length ?? 0) === 1) {
              this.message.info(`1 prestation renouvelée avec succès.`);
            } else {
              this.message.info(`${response.data?.length} prestations renouvelées avec succès.`);
            }
            this.actionLoading = false;
            this.checked.splice(0, this.checked.length);
            this.checkedChanged.emit();
          },
          error: (error: ApolloError) => {
            console.error(error);
            this.actionLoading = false;
            this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
          }
        });
      },
    });

    const openSubscription = modalReference.afterOpen.subscribe(() => {
      this.copyForm.setValue({
        date: null,
        time: null
      });
      this.copyForm.updateValueAndValidity();
    });

    const copySubscription = this.copyForm.valueChanges.subscribe(() => {
      const config = modalReference.getConfig();
      config.nzOkDisabled = this.copyForm.invalid;
      modalReference.updateConfig(config);
    });

    if (openSubscription && copySubscription) {
      this.subscriptions.push(openSubscription, copySubscription);
    }
  }

  deleteChecked(content: TemplateRef<any>, footer: TemplateRef<any>): void {
    this.modal.create({
      nzTitle: 'Annulation multiple',
      nzContent: content,
      nzFooter: footer
    });
  }

  closeCancelModal(modal: NzModalRef): void {
    if (this.cancelComment.invalid) {
      this.cancelComment.markAsDirty();
      this.cancelComment.updateValueAndValidity();
      return;
    }

    this.actionLoading = true;
    this.servicesService.cancelMultiple(this.cancelComment.value ?? '', ...this.deletable.map(s => s.id)).subscribe({
      next: response => {
        if ((response.data?.length ?? 0) === 0) {
          this.message.error('Une erreur est survenue lors de l\'annulation. Réessayer plus tard.');
        } else if ((response.data?.length ?? 0) === 1) {
          this.message.info(`1 prestation annulée avec succès.`);
        } else {
          this.message.info(`${response.data?.length} prestations annulées avec succès.`);
        }
        this.actionLoading = false;
        this.checked.splice(0, this.checked.length);
        this.checkedChanged.emit();
        modal.destroy();
      },
      error: (error: ApolloError) => {
        console.error(error);
        this.actionLoading = false;
        this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
      }
    });
  }

  doActionForChecked(action: ServiceActions): void {
    switch (action) {
      case ServiceActions.confirm: {
        this.confirm();
        break;
      }
      case ServiceActions.send_to_structure: {
        this.sendToStructure();
        break;
      }
      case ServiceActions.send_to_customer_site: {
        this.sendToCustomerSite();
        break;
      }
      case ServiceActions.perform: {
        this.perform();
        break;
      }
      case ServiceActions.vehicle_not_present: {
        this.vehicleNotPresent();
        break;
      }
    }
  }

  actionPerformed(event: { action: ServiceActions; loading: boolean; service: Service[]; error?: Error }): void {
    if (event.loading) {
      this.actionLoading = true;
      const loadingMessage = `${event.action === ServiceActions.perform || event.action === ServiceActions.vehicle_not_present ? 'Réalisation' : 'Envoi au site client'} en cours…`;
      this.currentLoadingMessageReference = this.message.loading(loadingMessage);
    } else {
      this.actionLoading = false;
      const id = this.currentLoadingMessageReference?.messageId;
      if (id) {
        this.confirmModal?.destroy();
        this.message.remove(id);
        this.currentLoadingMessageReference = undefined;
        if (event.error) {
          this.message.error(ApiErrorMessageUtil.getMessageFromError(event.error));
        } else {
          const errorMessage = `Une erreur est survenue lors de ${event.action === ServiceActions.perform || event.action === ServiceActions.vehicle_not_present ? 'la réalisation' : 'l\'envoi au site client'}. Réessayer plus tard.`;
          if (event.service.length === 0) {
            this.message.error(errorMessage);
          } else if (event.service.length === 1) {
            this.message.info(`1 prestation ${event.action === ServiceActions.perform ? 'réalisée' : 'envoyée au site client'} avec succès.`);
          } else {
            this.message.info(`${event.service.length} prestations ${event.action === ServiceActions.perform || event.action === ServiceActions.vehicle_not_present ? 'réalisées' : 'envoyées au site client'} avec succès.`);
          }

          this.checked.splice(0, this.checked.length);
          this.checkedChanged.emit();
        }
      }
    }
  }

  loadRole(): void {
    const roleSubscription = this.userService.currentRole.subscribe((role) => {
      if (role.type === UserRoleType.customer) {
        this.filterCientPlaceholder = 'Sites client';
        this.filterClientTypeEntity = [CustomerSite];
        this.userCanSeeStructureFilter = false;
      }

      if (role.type === UserRoleType.structure || role.type === UserRoleType.structureRead) {
        this.filterStructurePlaceholder = 'Préparateurs';
        this.filterStructureTypeEntity = [Preparer];
        this.isStructure = role.type === UserRoleType.structure; // TODO faire mieux comme par exemple ajouter un rôle spécial
      }

      if (
        role.type === UserRoleType.customerSite ||
        role.type === UserRoleType.customerSiteRead ||
        role.type === UserRoleType.preparer
      ) {
        this.userCanSeeCustomerFilter = false;
        this.userCanSeeStructureFilter = false;
      }

      if (role.type === UserRoleType.admin) {
        this.isAdmin = true;
      }

      if (role.type === UserRoleType.customerSite || role.type === UserRoleType.customerSiteRead) {
        const customerSite: CustomerSite = role.actor as CustomerSite;
        this.filterTypes = customerSite.type;
      }

      if (role.type !== UserRoleType.preparer) {
        this.userCanSeeInvoiceFilter = true;
      }
    });
    if (roleSubscription) {
      this.subscriptions.push(roleSubscription);
    }
  }

  exportExcel(): void {
    this.exportForm = this.formBuilder.group({
      search: this.formBuilder.control(this.filters.get('search')?.value),
      status: this.formBuilder.control(this.filters.get('status')?.value),
      customers: this.formBuilder.control(this.filters.get('customers')?.value),
      structures: this.formBuilder.control(this.filters.get('structures')?.value),
      types: this.formBuilder.control(this.filters.get('types')?.value),
      dates: this.formBuilder.control(this.filters.get('dates')?.value, [Validators.required]),
      invoiced: this.formBuilder.control(this.filters.get('invoiced')?.value),
      sort: this.formBuilder.control(this.filters.get('sort')?.value),
      page: this.formBuilder.control('current', [Validators.required]),
      exportType: this.formBuilder.control('services', [Validators.required]),
      filename: this.formBuilder.control('')
    });

    this.exportForm.get('search')?.disable();
    this.exportForm.get('status')?.disable();
    this.exportForm.get('customers')?.disable();
    this.exportForm.get('structures')?.disable();
    this.exportForm.get('types')?.disable();
    this.exportForm.get('invoiced')?.disable();
    this.exportForm.get('sort')?.disable();

    // const datesSubscription = this.exportForm.get('dates')?.valueChanges.subscribe(dates => {
    //   this.onDateRangeChange(dates);
    // });

    // if (datesSubscription) {
    //   this.subscriptions.push(datesSubscription);
    // }

    this.modal.create({
      nzTitle: 'Exporter en .xlsx',
      nzContent: this.exportFormContent,
      nzOkText: 'Exporter',
      nzCancelText: 'Annuler',
      nzData: {
        exportForm: this.exportForm
      },
      nzOkDisabled: !this.exportForm.valid,
      nzOnOk: () => {
        let filename: string = this.exportForm.get('filename')?.value ?? 'export';
        filename = filename.trim() === '' ? 'export' : filename.trim();
        filename = filename.toLowerCase().replaceAll(' ', '-');

        const dates: Date[] = this.exportForm.get('dates')?.value;
        const onlyCurrentPage = (this.exportForm.get('page')?.value ?? 'current') === 'current';
        const exportType = this.exportForm.get('exportType')?.value ?? 'services';

        const pagedRequest = new PagedRequest<ServicesFiltersRequest>({
          page: onlyCurrentPage ? this.queryStringFilters.value?.page : undefined,
          limit: onlyCurrentPage ? 10 : undefined,
          search: this.exportForm.get('search')?.value,
          filters: {
            minDate: dates[0],
            maxDate: dates[1],
            invoiced: this.exportForm.get('invoiced')?.value,
            types: this.exportForm.get('types')?.value,
            status: this.exportForm.get('status')?.value,
            customers: this.exportForm.get('customers')?.value.filter(c => c.__typename === 'Customer').map(c => c.id),
            customerSites: this.exportForm.get('customers')?.value.filter(c => c.__typename === 'CustomerSite').map(c => c.id),
            structures: this.exportForm.get('structures')?.value.filter(c => c.__typename === 'Structure').map(c => c.id),
            preparers: this.exportForm.get('structures')?.value.filter(c => c.__typename === 'Preparer').map(c => c.id),
          },
          sortType: this.exportForm.get('sort')?.value
        });

        this.servicesService.servicesExcel(pagedRequest, exportType).pipe(take(1)).subscribe({
          next: (url => {
            if (url.data) {
              Utils.download(url.data, filename);
            } else {
              console.error(url);
              this.message.error('Une erreur s\'est produite durant l\'export. Réessayez plus tard');
            }
          }),
          error: (error => {
            console.error(error);
            this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
          })
        });
      }
    });
  }

  private setFiltersForm(): void {
    this.filters = this.formBuilder.group({
      invoiced: this.formBuilder.control(null),
      search: this.formBuilder.control(null),
      status: this.formBuilder.control([]),
      customers: this.formBuilder.control([]),
      structures: this.formBuilder.control([]),
      types: this.formBuilder.control([]),
      dates: this.formBuilder.control([startOfToday(), addMonths(startOfToday(), 6)]),
      sort: this.formBuilder.control(this.sortType)
    });

    this.copyForm = this.formBuilder.group({
      date: this.formBuilder.control(null, [Validators.required]),
      time: this.formBuilder.control(null, [])
    });

    this.setFilterFromQueryString();

    this.dateRanges = {
      'Aujourd\'hui': [startOfToday(), endOfToday()],
      'Cette semaine': [startOfWeek(startOfToday(), { weekStartsOn: 1 }), endOfWeek(startOfToday(), { weekStartsOn: 1 })],
      'Ce mois': [startOfMonth(startOfToday()), endOfMonth(startOfToday())]
    };

    const filterSubscription = this.filters.valueChanges.pipe(debounceTime(500)).subscribe((filtersForm: FiltersFormValues) => {
      let minDate = filtersForm.dates.length > 0 ? filtersForm.dates[0] : undefined;
      let maxDate = filtersForm.dates.length > 0 ? filtersForm.dates[1] : undefined;
      if (minDate && isEqual(minDate, startOfToday()) && maxDate && isEqual(maxDate, addMonths(startOfToday(), 6))) {
        minDate = undefined;
        maxDate = undefined;
      }
      minDate?.setHours(0, 0, 0, 0);
      maxDate?.setHours(23, 59, 29, 999);

      this.filtersChanged.emit(new PagedRequest<ServicesFiltersRequest>({
        page: this.queryStringFilters.value?.page,
        filters: {
          minDate,
          maxDate,
          invoiced: filtersForm.invoiced,
          types: filtersForm.types,
          status: filtersForm.status,
          customers: filtersForm.customers.filter(c => c.__typename === 'Customer').map(c => c.id),
          customerSites: filtersForm.customers.filter(c => c.__typename === 'CustomerSite').map(c => c.id),
          structures: filtersForm.structures.filter(c => c.__typename === 'Structure').map(c => c.id),
          preparers: filtersForm.structures.filter(c => c.__typename === 'Preparer').map(c => c.id),
        },
        sortProperty: 'date',
        sortType: filtersForm.sort,
        search: filtersForm.search
      }));

      if (this.checked.length > 0) {
        this.checked.splice(0, this.checked.length);
        this.checkedChanged.emit();
      }
    });

    this.subscriptions.push(filterSubscription);
  }

  private setFilterFromQueryString(queryStringFilters: PagedRequest<ServicesFiltersRequest> | null = null): void {
    if (!queryStringFilters) {
      queryStringFilters = this.queryStringFilters.value;
    }

    if (!queryStringFilters) {
      return;
    }

    let dates: Date[] = this.filters.get('dates')?.value ?? [];
    if (queryStringFilters?.filters?.minDate && queryStringFilters?.filters?.maxDate) {
      dates = [queryStringFilters?.filters?.minDate, queryStringFilters?.filters?.maxDate];
    }

    const ids = [...(queryStringFilters?.filters?.customers ?? []), ...(queryStringFilters?.filters?.customerSites ?? []), ...(queryStringFilters?.filters?.structures ?? []), ...(queryStringFilters?.filters?.preparers ?? [])];
    const customers: (Customer | CustomerSite)[] = [];
    const structures: (Structure | Preparer)[] = [];

    const afterRequest = () => {
      this.filters.patchValue({
        search: queryStringFilters?.search,
        status: queryStringFilters?.filters?.status ?? [],
        customers,
        structures,
        types: queryStringFilters?.filters?.types ?? [],
        dates
      });

      let hasFilters = [
        this.filters.get('status')?.value,
        this.filters.get('customers')?.value,
        this.filters.get('structures')?.value,
        this.filters.get('types')?.value
      ].some(filter => filter?.length > 0);

      const filterDates = this.filters.get('dates')?.value as Date[];
      const hasFilterDates = filterDates.length > 0 && (!isEqual(filterDates[0], startOfToday()) || !isEqual(filterDates[1], addMonths(startOfToday(), 6)));
      hasFilters = hasFilters || hasFilterDates;

      this.filtersAreVisible = this.filtersAreVisible || hasFilters;
    };

    if (ids.length > 0) {
      this.actorsService.actorsByIds(ids).pipe(take(1)).subscribe({
        next: response => {
          response.data.forEach(actor => {
            if (actor instanceof Customer || actor instanceof CustomerSite) {
              customers.push(actor);
            }

            if (actor instanceof Structure || actor instanceof Preparer) {
              structures.push(actor);
            }
          });
          afterRequest();
        },
        error: (error: ApolloError) => {
          afterRequest();
          this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
        }});
    } else {
      afterRequest();
    }
  }

  // Checked actions
  private confirm(): void {
    const confirmable = this.checked.filter(service => service.actions.includes(ServiceActions.confirm));
    let nzContent = `Êtes-vous sûr de vouloir confirmer <b>${confirmable.length > 1 ? `les ${confirmable.length} prestations sélectionnées` : 'la prestation sélectionnée'}</b> ?`;
    if (confirmable.length !== this.checked.length) {
      const notConfirmableLength = this.checked.length - confirmable.length;
      nzContent += notConfirmableLength === 1 ? '<br><br> (' + notConfirmableLength + ' autre prestation ne peut pas être confirmée à cause de son statut)' : '<br><br> (' + notConfirmableLength + ' autres prestations ne peuvent pas être confirmées à cause de leurs statuts)';
    }

    this.modal.confirm({
      nzTitle: 'Confirmation multiple',
      nzContent,
      nzOkText: 'Confirmer',
      nzOkType: 'primary',
      nzCancelText: 'Annuler',
      nzOnOk: () => {
        this.actionLoading = true;
        const messageReference = this.message.loading('Confirmation en cours…');
        this.servicesService.confirmMultiple(...confirmable.map(s => s.id)).subscribe({
          next: response => {
            this.message.remove(messageReference.messageId);
            if ((response.data?.length ?? 0) === 0) {
              this.message.error('Une erreur est survenue lors de la confirmation. Réessayer plus tard.');
            } else if ((response.data?.length ?? 0) === 1) {
              this.message.info(`1 prestation confirmée avec succès.`);
            } else {
              this.message.info(`${response.data?.length} prestations confirmées avec succès.`);
            }
            this.actionLoading = false;
            this.checked.splice(0, this.checked.length);
          },
          error: (error: ApolloError) => {
            this.message.remove(messageReference.messageId);
            console.error(error);
            this.actionLoading = false;
            this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
          }
        });
      }
    });
  }

  private sendToStructure(): void {
    const sendable = this.checked.filter(service => service.actions.includes(ServiceActions.send_to_structure));
    let nzContent = `Êtes-vous sûr de vouloir envoyer <b>${sendable.length > 1 ? `les ${sendable.length} prestations sélectionnées` : 'la prestation sélectionnée'}</b> à la structure ?`;
    if (sendable.length !== this.checked.length) {
      const notSendableLength = this.checked.length - sendable.length;
      nzContent += notSendableLength === 1 ? '<br><br> (' + notSendableLength + ' autre prestation ne peut pas être envoyée à cause de son statut)' : '<br><br> (' + notSendableLength + ' autres prestations ne peuvent pas être envoyées à cause de leurs statuts)';
    }

    this.modal.confirm({
      nzTitle: 'Envoi multiple à la structure',
      nzContent,
      nzOkText: 'Envoyer',
      nzOkType: 'primary',
      nzCancelText: 'Annuler',
      nzOnOk: () => {
        this.actionLoading = true;
        const messageReference = this.message.loading('Envoi à la structure en cours…');
        this.servicesService.sendToStructureMultiple(...sendable.map(s => s.id)).subscribe({
          next: response => {
            this.message.remove(messageReference.messageId);
            if ((response.data?.length ?? 0) === 0) {
              this.message.error('Une erreur est survenue lors de l\'envoi à la structure. Réessayer plus tard.');
            } else if ((response.data?.length ?? 0) === 1) {
              this.message.info(`1 prestation envoyée avec succès.`);
            } else {
              this.message.info(`${response.data?.length} prestations envoyées avec succès.`);
            }
            this.actionLoading = false;
            this.checked.splice(0, this.checked.length);
          },
          error: (error: ApolloError) => {
            this.message.remove(messageReference.messageId);
            console.error(error);
            this.actionLoading = false;
            this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
          }
        });
      }
    });
  }

  private sendToCustomerSite(): void {
    const sendable = this.checked.filter(service => service.actions.includes(ServiceActions.send_to_customer_site));
    let content = `Êtes-vous sûr de vouloir envoyer <b>${sendable.length > 1 ? `les ${sendable.length} prestations sélectionnées` : 'la prestation sélectionnée'}</b> ?`;
    if (sendable.length !== this.checked.length) {
      const notSendableLength = this.checked.length - sendable.length;
      content += notSendableLength === 1 ? '<br><br> (' + notSendableLength + ' autre prestation ne peut pas être envoyée à cause de son statut)' : '<br><br> (' + notSendableLength + ' autres prestations ne peuvent pas être envoyées à cause de leurs statuts)';
    }

    const emitter = new EventEmitter<ServiceActions>();

    this.confirmModal = this.modal.create({
      nzTitle: 'Envoi multiple au site client',
      nzContent: this.workflowFormContent,
      nzData: {
        services: sendable,
        content,
        emitter
      },
      nzFooter: [
        {
          label: 'Annuler',
          onClick: () => this.confirmModal?.destroy()
        },
        {
          label: 'Envoyer',
          type: 'primary',
          onClick: () => emitter.emit(ServiceActions.send_to_customer_site)
        }
      ]
    });
  }

  private perform(): void {
    const performable = this.checked.filter(service => service.actions.includes(ServiceActions.perform));
    let content = `Êtes-vous sûr de vouloir réaliser <b>${performable.length > 1 ? `les ${performable.length} prestations sélectionnées` : 'la prestation sélectionnée'}</b> ?`;
    if (performable.length !== this.checked.length) {
      const notPerformableLength = this.checked.length - performable.length;
      content += notPerformableLength === 1 ? '<br><br> (' + notPerformableLength + ' autre prestation ne peut pas être réalisée à cause de son statut)' : '<br><br> (' + notPerformableLength + ' autres prestations ne peuvent pas être réalisées à cause de leurs statuts)';
    }

    const emitter = new EventEmitter<ServiceActions>();

    this.confirmModal = this.modal.create({
      nzTitle: 'Réalisation multiple',
      nzContent: this.workflowFormContent,
      nzData: {
        services: performable,
        content,
        emitter
      },
      nzFooter: [
        {
          label: 'Annuler',
          onClick: () => this.confirmModal?.destroy()
        },
        {
          label: 'Réaliser',
          type: 'primary',
          onClick: () => emitter.emit(ServiceActions.perform)
        }
      ]
    });
  }

  private vehicleNotPresent(): void {
    const notPresentable = this.checked.filter(service => service.actions.includes(ServiceActions.vehicle_not_present));
    let content = `Êtes-vous sûr de vouloir mettre en non présenté <b>${notPresentable.length > 1 ? `les ${notPresentable.length} prestations sélectionnées` : 'la prestation sélectionnée'}</b> ?`;
    if (notPresentable.length !== this.checked.length) {
      const notPerformableLength = this.checked.length - notPresentable.length;
      content += notPerformableLength === 1 ? '<br><br> (' + notPerformableLength + ' autre prestation ne peut pas être non présenté à cause de son statut)' : '<br><br> (' + notPerformableLength + ' autres prestations ne peuvent pas être non présenté à cause de leurs statuts)';
    }

    const emitter = new EventEmitter<ServiceActions>();

    this.confirmModal = this.modal.create({
      nzTitle: 'Véhicule non présent multiple',
      nzContent: this.workflowFormContent,
      nzData: {
        services: notPresentable,
        content,
        emitter
      },
      nzFooter: [
        {
          label: 'Annuler',
          onClick: () => this.confirmModal?.destroy()
        },
        {
          label: 'Mettre en non présent',
          type: 'primary',
          onClick: () => emitter.emit(ServiceActions.vehicle_not_present)
        }
      ]
    });
  }
}

interface FiltersFormValues {
  invoiced: boolean;
  search: string;
  status: ServiceStatus[];
  customers: (Customer | CustomerSite)[];
  structures: (Structure | Preparer)[];
  types: ServiceType[];
  dates: Date[];
  sort: 'ASC' | 'DESC';
}
