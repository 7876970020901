import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import { Subscription } from 'rxjs';
import { UserRight } from 'src/app/shared/models/entities/user-right';
import { UserService } from 'src/app/shared/services/api/user.service';
import { Service } from '../../../../shared/models/entities/service';
import { ServiceActions } from 'src/app/shared/models/entities/service-actions';
import { EntityWrapperComponent } from '../../shared/entity-wrapper/entity-wrapper.component';
import { pdfFileUploadOptions } from 'src/app/configs/file-upload.config';
import { ServicesService } from 'src/app/shared/services/api/services.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ApolloError } from '@apollo/client/core';
import { UserRoleType } from 'src/app/shared/models/entities/user-role';
import { ApiErrorMessageUtil } from '../../../../shared/utils/api-error-message.util';
import { CustomerSiteContractType } from 'src/app/shared/models/entities/customer-site-contract-type';
import { environment } from '../../../../../environments/environment';
import {StatusInvoice} from "../../../../shared/models/entities/invoice-request";

@Component({
  selector: 'laveo-service-detail',
  templateUrl: './service-detail.component.html',
  styleUrls: ['./service-detail.component.scss']
})
export class ServiceDetailComponent implements OnInit, OnDestroy, OnChanges {
  @Input() service?: Service;
  @Input() wrapper: EntityWrapperComponent;

  userCanReadCustomerSite = false;
  userCanReadVehicle = false;
  userCanReadStructure = false;
  userCanReadPreparer = false;
  userCanSeeInvoiced = false;
  userIsAdmin = false;
  showHistory = false;

  fileUploadOptions: any = {};
  apiUrl = environment.api.url;

  private subscriptions: Subscription[] = [];

  constructor(
    private readonly userService: UserService,
    private readonly servicesService: ServicesService,
    private readonly message: NzMessageService
  ) {}

  public get showActions(): boolean {
    return (this.service?.actions?.filter(a => a !== ServiceActions.update).length ?? 0) > 0;
  }

  public get hasActionUploadVehicleState(): boolean {
    return this.service?.actions?.includes(ServiceActions.upload_vehicle_state) ?? false;
  }

  public get contractIsTripartite(): boolean {
    return this.service?.vehicle.customerSite.contractType === CustomerSiteContractType.tripartite;
  }

  ngOnInit(): void {
    // console.log("Init service detail");
    this.loadRole();
    this.setFileUploadOptions();
  }

  ngOnDestroy(): void {
    for (const s of this.subscriptions) {
      s.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.service) {
      /*
      console.log('Service changed:', changes.service.currentValue);

      console.log("service status :", this.service?.status);
      console.log("user can see invoice:", this.userCanSeeInvoiced);
      console.log("contract is tripartite:" , this.contractIsTripartite);
      */

    }

  }

  toggleInvoiced() {
    const service = this.service;
    if (service) {
      this.servicesService.updateInvoicedService(service.id, !service.invoiced).subscribe(response => {
        if (response.data) {
          service.invoiced = response.data.invoiced;
        }
      });
    }
  }

  selectVehicleStatePdf(event: FocusEvent): void {
    (event.currentTarget as HTMLInputElement).select();
  }

  copyVehicleStatePdf(): void {
    if (this.service?.vehicleStatePdfShortLink) {
      void navigator.clipboard.writeText(this.service.vehicleStatePdfShortLink);
      this.message.success('Lien copié dans le presse-papier');
    }
  }

  removeVehicleStatePdf(): void {
    if (this.service?.id) {
      this.servicesService.removeVehicleState(this.service.id).subscribe(response => {
        if (response.data) {
          if (this.service) {
            this.service.vehicleStatePdf = undefined;
          }
          this.message.success('Rapport supprimé');
        }
      })
    }
  }

  private loadRole(): void {
    const roleSubscription = this.userService.currentRole.subscribe(role => {
      this.userCanReadCustomerSite = role.rights.customerSites.includes(UserRight.read);
      this.userCanReadVehicle = role.rights.vehicles.includes(UserRight.read);
      this.userCanReadStructure = role.rights.structures.includes(UserRight.read);
      this.userCanReadPreparer = role.rights.preparers.includes(UserRight.read);
      this.userCanSeeInvoiced = role.type !== UserRoleType.preparer && role.type !==  UserRoleType.structure && role.type !==  UserRoleType.structureRead;

      this.userIsAdmin = role.type === UserRoleType.admin;
    });

    if (roleSubscription) {
      this.subscriptions.push(roleSubscription);
    }
  }

  private setFileUploadOptions(): void {
    this.fileUploadOptions = pdfFileUploadOptions;
    this.fileUploadOptions.server = {
      process: (
        _fieldName: any,
        file: File,
        _metadata: any,
        load: (response: string) => void,
        error: (error_: string) => void,
        _progress: any,
        _abort: any,
        _transfer: any,
        _options: any
      ) => {
        if (!this.service) {
          return;
        }

        this.servicesService.uploadVehicleState(this.service.id, file).subscribe({
          next: result => {
            if (this.service && result.data) {
              const message = this.service.vehicleStatePdf ? 'État des lieux remplacé avec succès' : 'État des lieux ajouté avec succès';
              this.message.success(message);
              this.service.vehicleStatePdf = result.data.vehicleStatePdf;
              load(message);
            }
          },
          error: (error_: ApolloError) => {
            console.error(error_);
            this.message.error(ApiErrorMessageUtil.getMessageFromError(error_));
            error(error_.message);
          }
        });
      },
      revert: null,
      restore: null,
      load: null,
      fetch: null
    };
  }

  protected readonly StatusInvoice = StatusInvoice;
}
